.app_platform{

}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .app_platform{
    width: 100% !important;
    }
    
  }
  
  @media screen and (min-width: 576px) and (max-width: 768px) {}
  
  /* Small devices (portrait phones, 576px and up) */
  @media screen and (min-width: 576px) {}
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media screen and (min-width: 768px) {}
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media screen and (min-width: 992px) {}
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media screen and (min-width: 1200px) {}
  