resets
body {
    margin: 0px;
    padding: 0px;
}

.bg-blacks {
    background-color: #313131 !important;
}

.navbar-nav .active .nav-link {
    color: #FD7E14 !important;
    /* font-weight: bold; */
    border-bottom: 2px solid #FD7E14;
    transition: red 0.3s ease;
    width: 95% !important;
    font-weight: bold;
    margin: 0 auto;
    display: inline-block;
    /* Ensures the border wraps around the text */

}

.navbar-nav .nav-link:hover {
    border-bottom-color: #007bff;
    /* Change border color on hover */
}

.navbar-nav .nav-link:hover {
    border-bottom: 2px solid #FD7E14 !important;
    transition: red 0.3s ease;
}

/* main */
header {
    height: 101vh;
    z-index: 10;
}

.header-banner {
    /* background-color: #313131; */
    /* background-image: url('https://37.media.tumblr.com/8b4969985e84b2aa1ac8d3449475f1af/tumblr_n3iftvUesn1snvqtdo1_1280.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}



.fixed-header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
}

/* 
nav {
    width: 100%;
    height: 60px;
    background: #313131;
    postion: fixed;
    z-index: 10;
}

nav div {
    color: white;
    font-size: 2rem;
    line-height: 60px;
    position: absolute;
    top: 0;
    left: 2%;
    visibility: hidden;
}
.visible-title {
    visibility: visible;
}

nav ul { 
    list-style-type: none;
    margin: 0 2% auto 0;
    padding-left: 0;
    text-align: right;
    max-width: 100%;
}
nav ul li { 
    display: inline-block; 
    line-height: 60px;
    margin-left: 10px;
}
nav ul li a {
    text-decoration: none; 
    color: #a9abae;
} */

/* demo content */


body {
    color: #292f36;
    /* font-family: helvetica;
    line-height: 1.6; */
}
/* 
.main_carousal{
    display: flex;
} */
/* 
.main_carousal .react-multiple-carousel__arrow--left {
    background-color: #FD7E14 !important;
    left: calc(1% + 10px) !important;
}

.main_carousal .react-multiple-carousel__arrow--right {
    background-color: #FD7E14 !important;
    right: calc(0% + 0px) !important;
} */

.home_carousel-wrapper {
    position:relative !important;

}

.home_carousel {
    position:unset !important;

   
}

.home_carousel .react-multiple-carousel__arrow {
    position:absolute;
}

.home_carousel .react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px);
    background-color: #FD7E14 !important;
}

.home_carousel .react-multiple-carousel__arrow--right {
    right: calc(-3% + 1px);
    background-color: #FD7E14 !important;
}


.main_carousal .react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: white !important;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.portfolio .nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    color: var(--main-color);
}

.portfolio .nav-underline .nav-link {
    color: #313131;
    font-size: small;
    font-weight: bold;
}

.carousel-indicators {
    bottom: -10% !important;
}

.carousel-indicators .active {
    opacity: 1 !important;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: black;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.3;
    transition: opacity .6s ease;
    border-radius: 50%;
}

.navbar-toggler {
    border: 1px solid #FD7E14;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.banner_button {
    padding: 14px !important;
}

.design_container {
    background-color: #FAFAFA;

}

/* 
@media only screen and (max-width: 960px) {
    .content{ 
        padding: 2rem 0;
    }
    article {
        float: none;
        margin: 0 auto;
        width: 96%;
    }
    article:last-of-type {  
        margin-bottom: 3rem;
    }
    aside {  
        float: none;
        text-align: center;
        width: 100%;
    }
} */


.img-card:hover {
    color: white !important;
    background-color: var(--main-color) !important;
}

.img-card:hover svg path {
    fill: white !important;
}

.img-card:hover .card-body p {
    color: white !important;
}

.img-card:hover a {
    color: white !important;
}

.img-card:hover .fa-arrow-right-long {
    color: white !important;

}


@keyframes bounceUp {

    0%,
    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-100px);
    }

    80% {
        transform: translateY(-100px);
    }

    90%,
    100% {
        transform: translateY(0);
    }
}


@keyframes bounceDown {

    0%,
    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(100px);
    }

    80% {
        transform: translateY(100px);
    }

    90%,
    100% {
        transform: translateY(0);
    }
}

/* .bounce-up {
    animation: bounceUp 4s infinite;
}

.bounce-down {
    animation: bounceDown 4s infinite;
} */


.bounce1 {
    animation: bounce1 3s ease infinite;
}

@keyframes bounce1 {

    0%,
    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-100px);
    }

    30% {
        transform: translateY(-90px);
    }

    40% {
        transform: translateY(-100px);
    }

    50% {
        transform: translateY(-90px);
    }

    60% {
        transform: translateY(-100px);
    }

    70% {
        transform: translateY(-20px);
    }

    80% {
        transform: translateY(-10px);
    }

    90% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.bounce2 {
    animation: bounce2 3s ease infinite;
}

@keyframes bounce2 {

    0%,
    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(100px);
    }

    30% {
        transform: translateY(90px);
    }

    40% {
        transform: translateY(100px);
    }

    50% {
        transform: translateY(90px);
    }

    60% {
        transform: translateY(100px);
    }

    70% {
        transform: translateY(20px);
    }

    80% {
        transform: translateY(10px);
    }

    90% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }

}

.center-bounce {
    animation: center-bounce 2s ease infinite;
    z-index: -1;
}

@keyframes center-bounce {

    0%,
    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(5px);
    }

    

    50% {
        transform: translateY(10px);
    }



    80% {
        transform: translateY(10px);
    }

   

    100% {
        transform: translateY(0px);
    }

}

.card-body_carousl {
    position: relative;
    height: 350px
}

.hover-content {
    display: none;
    text-align: center;
}

.card-body_carousl:hover .hover-content {
    display: block;
}

.card-body_carousl:hover  {
    background-color: white;
    border-radius: var(--bs-border-radius-lg);
}
.card-body_carousl .text-center .hover_hide_img {
    transition: opacity 0.3s;
}

.card-body_carousl:hover .text-center .hover_hide_img {
    display: none;
}

.protfolio_card:hover img{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
}

.language_switch::after{
    color: #FD7E14 !important;
}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .header-banner .row {
        padding: 0 !important;
        margin: 0 !important;
    }

    .logo {
        width: 100px !important;
    }

    .navbar .container {
        background-color: #313131;
    }

    .navbar .container .navbar-collapse {
        padding: 20px 0px !important;
    }

    .navbar-nav .active .nav-link {
        color: #FD7E14 !important;
        border-bottom: 2px solid #FD7E14;
        transition: red 0.3s ease;
        width: unset !important;
        font-weight: bold;
        margin: 0 auto;
        display: inline-block;
    }

    .design_container img {
        width: 30% !important;
        margin: 0 auto;
        padding: 0 !important;
        padding-bottom: 20px !important;
    }

    .design_container div {

        height: auto !important;
    }

    .design_container {
        flex-wrap: wrap;
    }
    .navbar {
        background-color: #313131 !important;
    }

    .bounce1 {
        animation: unset !important;
        width: 85px;
    }
    .bounce2 {
        animation: unset !important;
        width: 85px;
    }
    .center-bounce {
        animation: unset !important;
        width: 500px !important;
    }
    .who_we_are{
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }

    
}

@media screen and (min-width: 576px) and (max-width: 768px) {}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}