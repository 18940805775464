.dropdown-toggle::after{
    font-size: large;
}

.dropdown-menu {
    display: none;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item.active, .dropdown-item:active {
    color: white !important;
    text-decoration: none !important;
    background-color: #fd7e14 !important;
  }