.our_values_circleContainer {
    position: relative;
    /* display: inline-block; */
   
    /* height: 100px; */
  }

  .our_values_circleContainer::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 470px;
    height: 470px;
    transform: translate(-50%, -50%);
    border: 4px dotted var(--main-color);
    border-radius: 50%;
    z-index: -1;
  }
  .c-parent{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-around;
  }
  .c-child{
    width: 40%;
    background: #FCFCFC;
    padding: 20px 28px;
  }