.faq .accordion-item{
    /* padding: 10px; */
    margin:10px 0;
    border: 3px solid #FD7E14 !important;
    border-radius: 6px;
}

.faq .accordion-button:not(.collapsed){
    background-color: #FD7E14;
    
}

.faq .accordion-button{
    padding: 25px 25px;    
    border-radius: 6px;
}