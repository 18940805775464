.footer_row {
  /* max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    box-sizing: border-box;
    overflow: hidden; */
  position: relative;
}

.footerBg{
  background-image: url('../img/footerBg.png');
  background-position: center;
  object-fit: contain;
}

.red-box {
  top: 0;
  left: 0;
  width: 100%;
  height: 83vh;
  display: block;
  /* position: absolute; */
  z-index: 1000;
  background: red;
  padding: 0px;
}

.blue-box {
  display: block;
  position: absolute;
  top: 0%;
  left: 25%;
  -webkit-transform: translateX(-50%);
  width: 30vw;
  height: 83vh;
  z-index: 1001;
  /* background:rgba(222,222,222,.6); */
  background-color: #1A202C;
  padding: 20px;
  box-shadow: .5px 4px 20px rgba(0, 0, 0, .5);
  ;
}
.footer_socialIcons{
  width: 70%;
  top: 570px;
  right: 7%;
}


@media screen and (min-width: 0px) and (max-width: 576px) {
  .footer_socialIcons{
    width: 45% !important;
    top: 570px;
    right: 7%;
  }
  
}

@media screen and (min-width: 576px) and (max-width: 768px) {}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}

